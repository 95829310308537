import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../api'
import Story from '../../Components/Story/Story'
import StoryBox from '../../Components/Story/StoryBox'
import SuggestBox from '../../Components/Suggestions/suggestBox'
import Suggestions from '../../Components/Suggestions/Suggestions'
import UserContext from '../../Context'
import Post from "./../../Components/Post/Post"

export default function Home() {

  const [posts, setPosts] = useState([])
  const [users, setUsers] = useState([])

  const { userInfos } = useContext(UserContext)

    async function fetchMyAPI() {
      let response = await fetch(`${api}posts.json`)
      response = await response.json()
      let postInfo = (Object.entries(response))
      setPosts(postInfo)
    }

  useEffect(() => {
    fetchMyAPI()
  }, [users, userInfos , posts])


   async function fetchMyAPIUSER() {
      let response = await fetch(`${api}users.json`)
      response = await response.json()

      let arrey = []

      for (const key in response) {
        if (userInfos.following?.some(item => item.username == response[key].username)) {
          const element = response[key];
        } else {
          arrey.push([key, response[key]])
        }
      }
      setUsers(arrey)
    }


  useEffect(() => {
    fetchMyAPIUSER()
  }, [userInfos])


  return (
    <div className='container py-5 row'>
      <div className='container col-md-5'>
        <div className='story-container '>
          <div className='story-main-div'>
            <StoryBox image="11.jpg" name="James" />
            <StoryBox image="10.jpg" name="beckham" />
            <StoryBox image="p1.jpg" name="loise" />
            <StoryBox image="er.jpg" name="marc" />
            <StoryBox image="p2.jpg" name="david" className="display-none-width370"/>
            <StoryBox image="po.jpg" name="crinus"  className="display-none-width430"/>
          </div>
        </div>
        {[...posts].reverse().map((post, index) => (<>
          <Post  key={post[0]} {...post[1]} postId={post[0]}/>
          {(index % 4 == 0 && index > 3) && <Suggestions />}
        </>))}
      </div>

      <div className='col-md-4 px-4 me-5 display-none-screen'>
        <div>
          <SuggestBox {...userInfos} display={"name"} profile={true}/>
          <div className='d-flex-b'>
            <h4>Suggestions for you</h4>
            <span >See all</span>
          </div>
        </div>
        {users.map(user => {
          if (user[1].name !== userInfos.name) {
            return <SuggestBox key={user[0]} {...user[1]} id={user[0]} />
          }
        })}
      </div>
    </div>
  )
}
