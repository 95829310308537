import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../api'
import ExploreBox from '../../Components/Explore/ExploreBox'
import Follow from '../../Components/Follow/Follow'
import Footer from '../../Components/Footer/Footer'
import Loader from '../../Components/Loader/Loader'
import "./Profile.css"

export default function Profile() {

    const [show, setShow] = useState("post")

    const [account, setAccount] = useState({})
    const [token, setToken] = useState({})
    const [posts, setPosts] = useState([])
    const [reels, setReels] = useState([])


    const { username } = useParams()

    useEffect(() => {
        fetch(`${api}users.json`)
            .then(res => res.json())
            .then(data => {
            
                let user = (Object.entries(data)).find(user => user[1].username === username)
                setAccount(user[1])
                setToken(user[0])
                setPosts(user[1]?.posts)
            })
    }, [username])


    return (
        <div className='container '>
            <div className='profile-container mx-auto'>
                {account ? (<>
                    <header className='d-flex align-items-center p-3'>
                        <div className='col-md-4 p-3' >
                            <img src={`/img/${account?.image}`} className='profile-account-image' />
                        </div>
                        <div className='col-md-8 p-3'>
                            <div className='d-flex align-items-center my-3 flex-wrap'>
                                <h2>{account?.name}</h2>
                                {document.cookie == token ? (
                                    <Link to="/account/edit">
                                        <button className='btn btn-light btn-lg mx-4'>Edit profile</button>
                                    </Link>) : (<Follow className="btn btn-primary mx-3 btn-lg" username={account.username} />)}
                                <span class="material-symbols-outlined">
                                    settings
                                </span>
                            </div>
                            <div className='d-flex align-items-center my-4'>
                                <span className='mx-3'>{posts?.length} post</span>
                                {account.follower ? (
                                    <>
                                        <Link to={`/followers/${account.username}`} className='mx-3'>
                                            {account.follower?.length} follower
                                        </Link>

                                    </>
                                ) : (<span className='mx-3'>0 follower</span>)}
                                {/* <span className='mx-4'>{account.follower?.length} followers</span> */}
                                {account.following ? (
                                    <>
                                        <Link to={`/following/${account.username}`}>
                                            {account.following?.length} following
                                        </Link>

                                    </>
                                ) : (<span>0 following</span>)}
                            </div>
                            <p>{account?.username}</p>
                        </div>
                    </header>

                    <div className='d-flex align-items-center border-top'>
                        <ul class="nav nav-tabs mx-auto">
                            <li class="nav-item " onClick={() => setShow("post")}>

                                <a class="nav-link text-secondary align-items-center d-flex" aria-current="page" href="#">
                                    <span class="material-symbols-outlined fs-4 mx-2">
                                        grid_on
                                    </span>
                                    POSTS
                                </a>
                            </li>
                            <li class="nav-item" onClick={() => setShow("save")}>
                                <a class="nav-link text-secondary mx-5 align-items-center d-flex" href="#">
                                    <span class="material-symbols-outlined fs-4 mx-2">
                                        smart_display
                                    </span>
                                    REELS
                                </a>
                            </li>
                            <li class="nav-item" onClick={() => setShow("tagg")}>
                                <a class="nav-link text-secondary align-items-center d-flex" href="#">
                                    <span class="material-symbols-outlined fs-4 mx-2">
                                        image_not_supported
                                    </span>
                                    TAGGED
                                </a>
                            </li>
                        </ul>
                    </div>

                    {show === "post" ? (
                        <div className='d-flex align-items-center my-3 flex-wrap'>
                            {posts?.length > 0 ? (<>
                                {posts.map(post => (<ExploreBox element="image" {...post} key={post.id} />))}
                            </>) : (<div className='d-flex align-items-center justify-content-center mx-auto my-3 p-5'>
                                <div className='my-5 text-center'>
                                    <span class="material-symbols-outlined font-size-100">
                                        perm_media
                                    </span>
                                    <h1>No Posts</h1>
                                </div>
                            </div>)}

                        </div>
                    ) : show === "save" ? (
                        <div className='d-flex align-items-center my-3'>

                            <ExploreBox element="reels" file="11.jpg" />
                            <ExploreBox element="reels" file="12.jpg" />
                            <ExploreBox element="reels" file="13.jpg" />

                        </div>
                    ) : (
                        <div className='d-flex align-items-center justify-content-center  my-3 p-5'>
                            <div className='my-5 text-center'>
                                <span class="material-symbols-outlined font-size-100">
                                    image_not_supported
                                </span>
                                <h1>No Photos</h1>
                            </div>
                        </div>
                    )}

                    <Footer />
                </>) : (<Loader />)}
            </div>
        </div>
    )
}
