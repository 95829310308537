import React, { useContext, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api } from '../../api'
import UserContext from '../../Context'
import "./EditAccount.css"


export default function EditAccount() {

    const [name, setName] = useState("")
    const [image, setImage] = useState("user.jpg")
    const [username, setUserame] = useState("")
    const [email, setEmail] = useState("")
    const [userInfos, setUserInfos] = useState({})
    const [refresh , setRefresh]= useState(false)
   

    const notify = () => toast("Profile saved!");

    useEffect(() => {


        fetch(`${api}users.json`)
            .then(res => res.json())
            .then(data => {
                let user = (Object.entries(data)).find(user => user[0] === document.cookie)
                setName(user[1].name)
                setUserame(user[1].username)
                setEmail(user[1].email)
                setUserInfos(user[1])
                setImage(user[1].image)
            })
    }, [refresh])

    const editAccount = () => {

        let date = new Date()
        let userId = document.cookie

        let newUser = {
            ...userInfos,
            name,
            email,
            username,
            date,
            image
        }

        console.log(newUser);

        fetch(`${api}users/${userId}.json`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newUser)
        }).then(res => {
            console.log(res)
            if (res.ok) {
                notify()
                setRefresh(prev => !prev)
            }
        })
    }
    
    console.log("edit.js");


    return (
        <div className='p-5' style={{ minHeight: "110vh" }}>
            <ToastContainer  theme="dark" position="bottom-right"/>
            <div className='px-5'>

                <table >
                    <tbody>

                        <tr className=' align-items-center'>
                            <td className='px-5  position-relative'>
                                <img src={`/img/${userInfos.image}`} className='post-header-image' />
                                <input onChange={e => setImage(e.target.value.slice(12))}
                                    type="file" className='edist-account-file-input' />
                            </td>
                            <td className='py-5'>

                                <h4 className='mx-2'>{name}</h4>
                                <h6 className='mx-2 text-primary'>Change profile photo</h6>

                            </td>
                        </tr>


                        <tr>
                            <td className='px-5'>
                                <label className=''>Name</label>
                            </td>
                            <td className='py-2'>
                                <input type="text" className='form-control py-2 fs-4' value={name}
                                    onChange={e => setName(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-2'><p className='fs-5'>
                                Help people discover your account by using the name you're known by:
                                either your full name, nickname, or business name.
                            </p></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-2'><p className='fs-5'>
                                You can only change your name twice within 14 days.
                            </p></td>
                        </tr>
                        <tr>
                            <td className='px-5'>
                                <label className=''>Username</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-2 fs-4'
                                    onChange={e => setUserame(e.target.value)}
                                    value={username} />
                            </td>
                        </tr>
                        <tr>
                            <td className='px-5'>
                                <label className=''>Website</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-2' />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-2'><p className='fs-5'>
                                Editing your links is only available on mobile.
                                Visit the Instagram app and edit your profile to change the websites in your bio.
                            </p></td>
                        </tr>

                        <tr>
                            <td className='px-5'>
                                <label className=''>Bio</label>
                            </td>
                            <td className='py-2'>
                                <input type="text" className='form-control py-2' />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-2'><p className='fs-5'>
                                0/150
                            </p></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-2'><p className='fs-5'>
                                Provide your personal information, even if the account is used for a business,
                                a pet or something else. This won't be a part of your public profile.
                            </p></td>
                        </tr>

                        <tr>
                            <td className='px-5'>
                                <label className=''>Email</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-2 fs-4'
                                    onChange={e => setEmail(e.target.value)}
                                    value={email} />
                            </td>
                        </tr><tr>
                            <td className='px-2'>
                                <label className=''>Phone number</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-2' />
                            </td>
                        </tr><tr>
                            <td className='px-5'>
                                <label className=''>Gender</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-2' />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-3 d-flex-b'>
                                <button onClick={editAccount}
                                    className='btn btn-primary btn-lg text-white'>Submit</button>
                                <a href='#' className='text-primary'>temprarly deactivat my account </a>
                            </td>
                        </tr>
                    </tbody>
                </table>



            </div>

        </div>
    )
}
