import React from 'react'
import "./Direct.css"

export default function Direct() {
    return (
        <div className='container py-5'>
            <div className='row border w-75 mx-auto h-75 direct-main-div'>
                <div className='col-md-4 border'>
                    <div className='row p-4 border-bottom'>
                        <div className='col-md-3 mx-auto row'>
                            <h3 className='col-md-8 '>user</h3>
                            <span class="material-symbols-outlined col-md-1">
                                expand_more
                            </span>
                        </div>

                        <span class="material-symbols-outlined col-md-1">
                            edit_note
                        </span>
                    </div>

                    <div className='px-3 text-center col-md-12 m-3 d-flex-b'>
                        <div className='d-flex-b'>
                            <img src='/img/1.jpg' className='post-header-image' />
                            <div>
                                <h5 className='m-2'>ronaldo</h5>
                                <h6 className='m-2'>Follow by mick</h6>
                            </div>
                        </div>
                    </div>
                    <div className='px-3 text-center col-md-12 m-3 d-flex-b'>
                        <div className='d-flex-b'>
                            <img src='/img/1.jpg' className='post-header-image' />
                            <div>
                                <h5 className='m-2'>ronaldo</h5>
                                <h6 className='m-2'>Follow by mick</h6>
                            </div>
                        </div>
                    </div>
                    <div className='px-3 text-center col-md-12 m-3 d-flex-b'>
                        <div className='d-flex-b'>
                            <img src='/img/1.jpg' className='post-header-image' />
                            <div>
                                <h5 className='m-2'>ronaldo</h5>
                                <h6 className='m-2'>Follow by mick</h6>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-md-8 border d-flex align-items-center justify-content-center'>
                    <div className='text-center  '>
                        <span class="material-symbols-outlined font-size-100">
                            play_circle
                        </span>
                        <h2>Your Meesage</h2>
                        <p className='m-3'>Send private photos and messages to a friend or group.</p>
                        <button className='btn btn-primary text-white btn-lg'>send message</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
