import React, { useReducer } from 'react'



const reducer = (state , action) => {
    switch (action.type){
        case "FORM" : {
            let formValid = true 

            for (const key in state.inputs) {
                if (key === action.id) {
                    formValid = formValid && action.isValid
                } else {
                    formValid = formValid && state.inputs[key].isValid
                }
            }

            return {
                ...state,
                inputs:{
                     ...state.inputs,
                     [action.id]:{
                        value:action.value ,
                        isValid : action.isValid
                     }
                },
                inputsValid : formValid
               
            }
        }
        default :{
            return state
        }
    }
}


export default function useForm(inputs , inputsValid) {

    const [formState , dispatch] = useReducer(reducer , {
        inputs ,
        inputsValid
    })

    const inputHandler = (id , value , isValid) => {
        dispatch({
            type:"FORM",
            id , value , isValid
        })
    }

  return [formState , inputHandler]
}
