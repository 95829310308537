import React from 'react'

export default function StoryBox({image , name , className}) {

  return (
    <div className={`p-3 story-box-container ${className}`}>
        <img src={`/img/${image}`} className='story-box-image'/>
        <h5>{name}</h5>
    </div>
  )
}
