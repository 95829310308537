import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../../api'
import "./Post.css"

export default function Post({ file, username, caption, location, date, postId, image, id, likes, comments }) {

  const [like, setLike] = useState(false)
  const [bookmark, setBookmark] = useState(false)
  const [likeCount, setLikeCount] = useState(likes)
  const [disable, setDisable] = useState(false)

  const [comment, setComment] = useState("")

  const likeHandler = (postId) => {

    if (!like) {
      let newPost;
      if (likes) {
        newPost = {
          file,
          caption,
          location,
          date,
          id,
          username,
          image,
          likes: Number(likes) + 1,
          comments
        }
      } else {
        newPost = {
          file,
          caption,
          location,
          date,
          id,
          username,
          image,
          likes: 1,
          comments
        }
      }

      fetch(`${api}posts/${postId}.json`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...newPost })
      }).then(res => {
        console.log(res);
      })
    }

    setLike(prev => !prev)
    if (!like) {
      setLikeCount(prev => prev + 1)
    } else {
      setLikeCount(prev => prev - 1)
    }
  }


  const commentHandler = (postId) => {

    if (comment.length > 1) {
      setDisable(true)
      let newPost;
      if (comments) {
        let newComments = [...comments, comment]
        newPost = {
          file,
          caption,
          location,
          date,
          id,
          username,
          image,
          likes,
          comments: newComments
        }
      } else {
        newPost = {
          file,
          caption,
          location,
          date,
          id,
          username,
          image,
          likes,
          comments: [comment]
        }
      }

      fetch(`${api}posts/${postId}.json`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...newPost })
      }).then(res => {
        console.log(res);
      })
    }
    setTimeout(() => {
      setDisable(false)
      setComment("")
    }, 3000);

  }



  return (
    <div className='post-container '>
      <div >
        <div className='post-header '>
          <Link to={`/profile/${username}`}>
            <div className='post-header-account'>
              <img src={`/img/${image}`} className='post-header-image' />
              <div>
                <h4 className=''>{username}</h4>
                <h6>{location}</h6>
              </div>
            </div>
          </Link>
          <span class="material-symbols-outlined">more_horiz</span>
        </div>
        <div className='post-main-image-div'>
          <img src={`/img/${file}`} className='post-main-image' />
        </div>
        <div className='post-footer px-3'>
          <div className=' d-flex-b py-3 aligen-items-center'>
            <div className='post-footer-icons-div'>
              {like ? (
                <i class='fa fa-heart '
                  onClick={() => likeHandler(postId)}
                ></i>) : (
                <span onClick={() => likeHandler(postId)}
                  class={`material-symbols-outlined `} >
                  favorite
                </span>)}

              <span class="material-symbols-outlined">
                mode_comment
              </span>
              <span class="material-symbols-outlined">
                send
              </span>
            </div>
            <div className='post-footer-icons-div'>
              {bookmark ? (
                <i class="fa fa-bookmark"
                  onClick={() => setBookmark(prev => !prev)}
                ></i>) : (
                <span onClick={() => setBookmark(prev => !prev)}
                  class="material-symbols-outlined">
                  bookmark
                </span>)}
            </div>
          </div>
          <div >
            <h4>{likeCount || 0} likes</h4>
          </div>
          <div className='py-3'>
            <p><h4 className='d-inline'>{username}</h4>&nbsp;
              {caption}...
              <span className='post-more'>more</span>
            </p>
            <Link to={`/post/${id}`}>
              <p className='post-more py-2'
              >{comments ? `view all ${comments.length} comments` : "No comment"}</p>
            </Link>
            <h6 className='post-more'>4 DAYS AGO</h6>
          </div>


        </div>
        <div className=' post-comment px-3'>
          <span class="material-symbols-outlined">
            sentiment_satisfied
          </span>
          <input type="text" placeholder='add a comment...' value={comment} onChange={e => setComment(e.target.value)} />
          <button className='text-primary btn fs-4' onClick={() => commentHandler(postId)} disabled={disable}>Post</button>
        </div>
      </div>
    </div>
  )
}

