import Account from "./Pages/Account/Account"
import ChangePassword from "./Pages/Account/ChangePassword"
import EditAccount from "./Pages/Account/EditAccount"
import Direct from "./Pages/Direct/Direct"
import Explore from "./Pages/Explore/Explore"
import Followers from "./Pages/Followers/Followers"
import Following from "./Pages/Following/Following"
import Home from "./Pages/Home/Home"
import Index from "./Pages/index"
import Login from "./Pages/Login/Login"
import PostModal from "./Pages/PostModal/PostModal"
import Profile from "./Pages/Profile/Profile"
import Reels from "./Pages/Reels/Reels"
import Register from "./Pages/Register/Register"



const routes = [
    {path:"/" ,element : <Index /> , children:[
        {path:"" ,element : <Home />},
        {path:"/explore" ,element : <Explore />},
        {path:"/reels" ,element : <Reels />},
        {path:"/direct" ,element : <Direct />},
        {path:"/profile/:username" ,element : <Profile />},
        {path:"/following/:username" ,element : <Following />},
        {path:"/followers/:username" ,element : <Followers />},
        


        {path:"/account" ,element : <Account /> , children:[
            {path:"edit" , element: <EditAccount />},
            {path:"changepassword" , element: <ChangePassword />},
        ]},
    ]},
    
    {path:"/login" ,element : <Login />},
    {path:"/register" ,element : <Register />},
    {path:"/post/:postId" ,element : <PostModal />},
    
]


export default routes