import React, { useContext, useEffect, useState } from 'react'
import "./CreateModal.css"
import UserContext from "./../../../Context"
import { api } from "./../../../api"

export default function CreateModal({ show }) {

    const [file, setFile] = useState(null)
    const [shareSection, setShareSection] = useState(null)
    const [caption, setCaption] = useState("")
    const [location, setLocation] = useState("")
    const [finish, setFinish] = useState(false)
    

    const [user, setUser] = useState([])

    useEffect(() => {

        async function fetchMyAPI() {
            let response = await fetch(`${api}users/${document.cookie}.json`)
            response = await response.json()
            setUser(response)
        }

        fetchMyAPI()
    }, [])

    const SharePhoto = () => {

        let date = new Date()

        let id = Math.floor(Math.random() * 999999999)

        if (!user.posts) {
            user.posts = [{
                file,
                caption,
                location,
                date,
                id
            }]

        } else {
            user.posts.push({
                file,
                caption,
                location,
                date,
                id
            })
        }


        fetch(`${api}users/${document.cookie}.json`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...user })
        }).then(res => {
            if (res.ok) {
                setFinish(true)
            }
        })

        fetch(`${api}posts.json`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                file,
                caption,
                location,
                date,
                id,
                username: user.username,
                image: user.image,
                likes:0,
                usretoken:document.cookie
            })
        }).then(res => {
            if (res.ok) {
                setFinish(true)
            }
        })
    }

    return (
        <div className='create-modal-container'>
            <div className='w-100 px-5'>
                <span onClick={() => show(false)}
                    className=' text-white fs-1  btn float-end'
                >X</span>
            </div>
            {finish ? (
                <div className='create-modal-display'>
                    <div className='create-modal-header d-flex-b border-bottom p-3'>
                        <h4 className='mx-auto '>Post shared</h4>
                    </div>
                    <div className='create-modal-main div-width-500 h-100'>
                        <span class="material-symbols-outlined font-size-100 text-danger">
                            check
                        </span>
                        <h3 className='my-5'>Your post has been shared.</h3>
                    </div>
                </div>
            ) : (
                <div className='create-modal-display'>
                    <div className='create-modal-header d-flex-b border-bottom p-3'>

                        {file ? (
                            <>
                                <span class="material-symbols-outlined ">
                                    keyboard_backspace
                                </span>
                                <h3 className='mx-auto '>Crop</h3>
                                {shareSection === "share" ? (
                                    <span onClick={() => SharePhoto()}
                                        className='text-primary btn fs-5'>
                                        Share
                                    </span>) : (
                                    <span onClick={() => setShareSection("share")}
                                        className='text-primary btn fs-5'>
                                        Next
                                    </span>)}
                            </>
                        ) : (
                            <h3 className='mx-auto '>Create new post</h3>)}
                    </div>
                    {file ? (
                        <div className='d-flex'>
                            <div className='div-width-500 h-100 text-center'>
                                <img src={`/img/${file}`} className="create-content-image" />

                                {shareSection === null &&
                                    <div className='create-content-div-icons'>
                                        <div>
                                            <span class="material-symbols-outlined icon-border-bg">
                                                zoom_out_map
                                            </span>
                                            <span class="material-symbols-outlined mx-3 icon-border-bg">
                                                zoom_in
                                            </span>
                                        </div>
                                        <span class="material-symbols-outlined icon-border-bg">
                                            perm_media
                                        </span>
                                    </div>}
                            </div>
                            {shareSection === "share" &&
                                <div className='create-modal-caption-div '>
                                    <div className=' d-flex align-items-center text-left'>
                                        <img src={`/img/${user.image}`} className='story-box-image' />
                                        <h5 className='mx-3'>{user.name}</h5>
                                    </div>
                                    <div className='my-3'>
                                        <textarea value={caption} onChange={e => setCaption(e.target.value)}
                                            className='w-100 border-none mb-5 h-100' placeholder='Write a caption...'>
                                        </textarea>
                                    </div>
                                    <div className='d-flex-b my-3'>
                                        <span class="material-symbols-outlined">
                                            sentiment_satisfied
                                        </span>
                                        <span>0/2200</span>
                                    </div>
                                    <div>
                                        <input value={location} onChange={e => setLocation(e.target.value)}
                                            type="text" className='form-control border-none p-3' placeholder='Add a location' />
                                    </div>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header " id="headingOne">
                                                <button class="accordion-button py-3 fs-3 text-secondry" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Accessbility
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p className='fs-6'>
                                                        Alt text describes your photos for people with visual impairments. Alt text will be automatically
                                                        created for your photos or you can choose to write your own.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button py-3 fs-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Advance setting
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds
                                                    the appropriate classes that we use to style each element. These classes control earance, n the <code>.accordion-body</code>, though
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>}

                        </div>

                    ) : (
                        <div className='create-modal-main div-width-500 h-100'>

                            <span class="material-symbols-outlined font-size-100">
                                panorama
                            </span>
                            <h3 className='my-5'>Drag photos and videos here</h3>
                            <input onChange={e => setFile(e.target.value.slice(12))}
                                type="file" className='create-content-file-input' />
                        </div>)}
                </div>)}
        </div>
    )
}
