import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { api } from '../../api'
import UserContext from '../../Context'
import CreateModal from '../Modals/CreateModal/CreateModal'
import LeftModal from '../Modals/LeftModal/LeftModal'
import "./Sidebar.css"

export default function Sidebar() {

    const [showMenu, setShowMenu] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showNotif, setShowNotif] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [searchValue , setSearchValue] = useState("")
    const [searchResult , setSearchResult] = useState([])
    

    let userContext = useContext(UserContext)


    let navigate = useNavigate()

    const searchHandler = e => {
        setSearchValue(e.target.value)

        fetch(`${api}users.json`)
            .then(res => res.json())
            .then(users => {
                let arrey = []
                for (const key in users) {
                    if (users[key].name.includes(e.target.value)) {
                        const element = users[key];
                        console.log(element);
                        arrey.push(element)
                    }
                }
                console.log(arrey);
                setSearchResult(arrey)
            })
    }

    return (
        <>
            {showCreateModal &&
                <CreateModal show={setShowCreateModal} />}
            <div className='sidebar-container ' >
                {showSearch || showNotif ? (
                    <h1 className='main-logo display-none-screen'>
                        <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                            image
                        </span>
                    </h1>
                ) : (<h1 className='main-logo display-none-screen'>instagram</h1>)}

                <ul className='main-list-menu'
                onClick={() => {
                    setShowMenu(false)
                    setShowSearch(false)
                    setShowNotif(false)
                }}>
                    <Link to="/">
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                home
                            </span>
                            <span className='px-4 display-none-screen'>Home</span>
                        </li>
                    </Link>
                    <Link className='display-none-screen'
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowSearch(prev => !prev)
                        setShowNotif(false)
                    }}>
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                search
                            </span>
                            <span className='px-4 display-none-screen'>Search</span>
                        </li>
                    </Link>
                    <Link to="/explore">
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                explore
                            </span>
                            <span className='px-4 display-none-screen'>Explore</span>
                        </li>
                    </Link>
                    <Link to="/reels">
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                smart_display
                            </span>
                            <span className='px-4 display-none-screen'>Reels</span>
                        </li>
                    </Link>
                    <Link to="/direct" className='display-none-screen'>
                        <li className='sidebar-list '>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon display-none-screen">
                                maps_ugc
                            </span>
                            <span className='px-4 display-none-screen'>Messeges</span>
                        </li>
                    </Link>
                    <Link className='display-none-screen'
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowNotif(prev => !prev)
                        setShowSearch(false)
                    }}>
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon display-none-screen">
                                favorite
                            </span>
                            <span className='px-4 display-none-screen'>Notifications</span>
                        </li>
                    </Link>
                    <Link onClick={() => setShowCreateModal(true)}>
                        <li className='sidebar-list'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                add_circle
                            </span>
                            <span className='px-4 display-none-screen'>Create</span>
                        </li>
                    </Link>
                    <Link to={`/profile/${userContext.userInfos?.username}`}>
                        <li className='sidebar-list mb-5'>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                account_circle
                            </span>
                            <span className='px-4 display-none-screen'>Profile</span>
                        </li>
                    </Link>
                    <div className='sidebar-menu-div' >
                        <li className='sidebar-list' onClick={(event) => {
                            event.stopPropagation()
                            setShowMenu(prev => !prev)
                        }}>
                            <span class="material-symbols-outlined fw-normal sidebar-list-icon">
                                menu
                            </span>
                            <span className='px-4 display-none-screen'>More</span>
                        </li>
                        {showMenu &&
                            <div className='sidebar-drop-menu'>
                                <ul>
                                    <Link to="account/edit">
                                        <li className='sidebar-menu-list d-flex-b align-items-center'>
                                            <span>
                                                Settings
                                            </span>
                                            <span class="material-symbols-outlined fw-normal">
                                                settings
                                            </span>
                                        </li>
                                    </Link>
                                    <li className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Saved
                                        </span>
                                        <span class="material-symbols-outlined fw-normal">
                                            bookmark
                                        </span>
                                    </li>
                                    <li className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Switch appearance
                                        </span>
                                        <span class="material-symbols-outlined fw-normal">
                                            dark_mode
                                        </span>
                                    </li>
                                    <li className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Your activity
                                        </span>
                                        <span class="material-symbols-outlined fw-normal">
                                            history_toggle_off
                                        </span>
                                    </li>
                                    <li className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Report a problem
                                        </span>
                                        <span class="material-symbols-outlined fw-normal">
                                            problem
                                        </span>
                                    </li>
                                    <li className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Switch account
                                        </span>

                                    </li>
                                    <li onClick={() => {
                                        userContext.LogOutUser()
                                        navigate("/login")
                                    }}
                                        className='sidebar-menu-list d-flex-b align-items-center'>
                                        <span>
                                            Log out
                                        </span>
                                    </li>
                                </ul>
                            </div>}
                    </div>
                </ul>
                {
                    showSearch &&
                    <LeftModal title="Search">
                        <div className='border-bottom pb-5 z-3'>
                            <div className='d-flex-b border bg-light p-2 rounded'>
                                <input value={searchValue} onChange={e => searchHandler(e) }
                                type="text" className='p-3 w-100 form-control bg-light  border-none' placeholder='Search...' />
                                <button onClick={()=> {
                                    setSearchValue("")
                                    setSearchResult([])
                                }}
                                type="button" class="btn-close " aria-label="Close"></button>
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className='d-flex-b'>
                                <h3>Recent</h3>
                                <h2 onClick={()=> {
                                    setSearchValue("")
                                    setSearchResult([])
                                }}
                                className='text-primary btn'>Cleer all</h2>
                            </div>

                            <div className='p-3'>
                                {searchResult.map(user => (
                                    <div className='px-3  col-md-12 m-3 d-flex-b'>
                                        <Link to={`/profile/${user.username}`} onClick={()=> setShowSearch(false)}>
                                    <div className='d-flex-b'>
                                        <img src={`/img/${user.image}`} className='post-header-image' />
                                        <div className=''>
                                            <h4 className='m-2'>{user.name}</h4>
                                            <h6 className='m-2'>roy frdinand</h6>

                                        </div>
                                    </div></Link>
                                    <button className='btn text-white btn-primary btn-lg'>Follow</button>
                                </div>
                                ))}
                                
                            </div>
                        </div>
                    </LeftModal>}
                {
                    showNotif &&
                    <LeftModal title="Notifications">
                        <div className='border-bottom pb-3'>
                            <h3>New</h3>

                            <div className='p-3'>
                                <div className='px-3 text-center col-md-12 m-3 d-flex-b'>
                                    <div className='d-flex-b'>
                                        <img src='/img/1.jpg' className='post-header-image' />
                                        <div className='d-flex align-items-center'>
                                            <h4 className='m-2'>frdinand</h4>
                                            <h6 className='m-2'>started fallowing you</h6>
                                            <p className='fs-5'>45m</p>
                                        </div>
                                    </div>
                                    <button className='btn text-white btn-primary btn-lg'>Follow</button>
                                </div>
                            </div>
                        </div>
                        <div className='pb-5 p-3'>
                            <h3>Today</h3>

                            <div className=''>
                                <div className='px-3 text-center col-md-12 m-3 d-flex-b'>
                                    <div className='d-flex-b'>
                                        <img src='/img/1.jpg' className='post-header-image' />
                                        <div className='d-flex align-items-center'>
                                            <h4 className='m-2'>ronaldo</h4>
                                            <h6 className='m-2'>started fallowing you</h6>
                                            <p className='fs-5'>16h</p>
                                        </div>
                                    </div>
                                    <button className='btn text-white btn-primary btn-lg'>Follow</button>
                                </div>
                            </div>
                        </div>
                    </LeftModal>}


            </div>
        </>
    )
}
