import React from 'react'

export default function ChangePassword() {
    return (
        <div className='p-5' style={{minHeight:"110vh"}}>
            <div className='px-5'>

                <table >
                    <tbody >

                        <tr >
                            <td className='px-2'>
                                <img src='/img/1.jpg' className='post-header-image' />
                            </td>
                            <td className='py-5'>

                                ronaldo


                            </td>
                        </tr>


                        <tr>
                            <td className='px-2'>
                                <label className=''>Old password</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-3' style={{ width: 400 }} />
                            </td>
                        </tr>
                        <tr>
                            <td className='px-2'>
                                <label className=''>New password</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-3' />
                            </td>
                        </tr>
                        <tr>
                            <td className='px-2'>
                                <label className=''>Confirm password</label>
                            </td>
                            <td className='py-3'>
                                <input type="text" className='form-control py-3' />
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td className='py-3'>
                                <button className='btn btn-primary btn-lg text-white'>Chenge password</button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='py-3'>
                                <p className='text-primary'>Forgote password ?</p>
                            </td>
                        </tr>

                    </tbody>
                </table>



            </div>
        </div>
    )
}
