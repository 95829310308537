import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../api'
import UserContext from '../../Context'

export default function Follow({ className, username, image, fetchId }) {

    const [user, setUser] = useState([])
    const [followerAccount, setFollowerAccount] = useState(null)
    const [account, setAccount] = useState({})
    const [follow , setFollow] = useState(true)

    const userContext = useContext(UserContext)


    useEffect(() => {

        async function fetchMyAPI() {
            let response = await fetch(`${api}users/${document.cookie}.json`)
            response = await response.json()
            setUser(response)
        }

        async function fetchUsersAPI() {
            let response = await fetch(`${api}users.json`)
            response = await response.json()
            setFollowerAccount(response)
        }


        fetchMyAPI()
        fetchUsersAPI()
    }, [])


    const followHandler = () => {
        let newUser = followerAccount[fetchId]
        //start following handler 
        let date = new Date()

        let id = Math.floor(Math.random() * 999999999)

        if (!user.following) {
            user.following = [{
                username,
                date,
                id,
                image
            }]

        } else {
            user.following.push({
                username,
                date,
                id,
                image
            })
        }


        fetch(`${api}users/${document.cookie}.json`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...user })
        }).then(res => {
            console.log(res);
        })
        //end following handler 


        //start follower handler 
    
        if (!newUser["follower"]) {
            newUser["follower"] = [document.cookie]

        } else {
        
        newUser["follower"].push(document.cookie)
        }

        console.log(newUser);

        fetch(`${api}users/${fetchId}.json`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...newUser })
        }).then(res => {
            console.log(res);
            if(res.ok){
                setFollow(false)
            }
        })
        
    }



    return (<>
    {follow ? (
        <span className={className} onClick={followHandler}>Follow</span>) :
         (<span className='fs-5' >Following</span>)}
        </>
    )
}
