import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../api'



export default function Followers() {

    const [accounts, setAccounts] = useState([])
    const [users, setUsers] = useState([])
    const [followers, setfollowers] = useState([])

    const { username } = useParams()

    useEffect(() => {

        async function fetchMyAPI() {
            let response = await fetch(`${api}users.json`)
            response = await response.json()
            setUsers(response)
            for (const key in response) {
                if (response[key].username == username) {
                    const element = response[key];
                    setfollowers(element.follower)
                }
            }
            
          
        }

        fetchMyAPI()

    }, [username ])

    useEffect(() => {
        for (const key in users) {
            if (followers.some(item => item == key )) {
                const element = users[key];
                setAccounts(prev => [...prev , element])
            }
        }
    } , [followers])



    return (
        <div className='following-container'>
            <div className='following-main'>
                <div className='text-center p-3 border-bottom d-flex-b'>
                    <div></div>
                    <h4 >Followers</h4>
                    <Link to={`/profile/${username}`}>
                        <button className='btn btn-close'></button>
                    </Link>
                </div>
                {accounts ? ( 
                <div>
                    {accounts.map(user => (
                    <div className='px-3 text-center col-md-12 m-3 d-flex-b p-3' key={user?.id}>
                        <Link to={`/profile/${user?.username}`}>
                            <div className='d-flex-b'>
                                <img src={`/img/${user?.image  || "12.jpg"}`} className='post-header-image' />
                                <div>
                                    <h5 className='m-2'>{user?.username}</h5>
                                    <h6 className='m-2'>Follow by mick</h6>
                                </div>
                            </div>
                        </Link>
                        <span className='btn btn-light btn-lg' >Follower</span>
                    </div>
                    ))}
                </div>) : (<div>no follower</div>)
            }
            </div>
        </div >
    )
}
