import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Loader from '../Components/Loader/Loader'
import Sidebar from '../Components/Sidebar/Sidebar'
import UserContext from '../Context'
import Login from './Login/Login'

export default function Index() {

    const {isLogin , isLoading} = useContext(UserContext)
    
    return (
        <>
        {isLoading ? (<div className='d-flex w-100 h-100 align-items-center justify-content-center my-5 py-5'>
            <Loader /></div>) : (<>
        {isLogin ? (
            <div className='row'>
                <div className='col-md-2'>
                    <Sidebar />
                </div>
                <div className='col-md-10 index-main-container'>
                    <div className='container px-5 '>
                        <Outlet />
                    </div>
                </div>
            </div>
            ) : (<Login/>)}
            </>)}
        </>
    )
}
