import React, { useEffect, useReducer, useState } from 'react'
import validator from '../../Validations/validator'
import "./Input.css"

const reducer = ((state, action) => {
    switch (action.type) {
        case "INPUT": {
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        }
        default: {
            return state
        }
    }
})

export default function Input({ type, className, placeholder, valdations, inputHandler, id }) {
    const[show , setShow] = useState(false)
    const [inputValue, dispatch] = useReducer(reducer, {
        value: "",
        isValid: false
    })

    const changeHandler = (e) => {
        dispatch({
            type: "INPUT",
            value: e.target.value,
            isValid: validator(e.target.value, valdations)
        })

    }

    useEffect(() => {
        inputHandler(id, inputValue.value, inputValue.isValid)
    }, [inputValue])


    return (
        <div className='position-relative'>
            <input
            onBlur={()=> setShow(!inputValue.isValid)}
                value={inputValue.value} onChange={e => changeHandler(e)}
                type={type} className={className} placeholder={placeholder} />
            {
                show &&

                <span className='input-span-validation material-symbols-outlined'>close</span>}
        </div>

    )
}
