import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import Input from '../../Components/Input/Input'
import "./Login.css"
import { reqValid, minValid, maxValid } from "./../../Validations/rouls"
import useForm from '../../Components/MyHooks/useForm'
import { api } from '../../api'
import Register from '../Register/Register'

export default function Login() {
    const [show , setShow] = useState("login")
    const [formState, inputHandler] = useForm({
        username: {
            value: "",
            isValid: false
        },
        password: {
            value: "",
            isValid: false
        },
    }, false)

    let navigate = useNavigate()

    const getUser = (e) => {
        e.preventDefault()

        if (formState.inputsValid) {

            let username = formState.inputs.username.value
            let password = formState.inputs.password.value

            fetch(`${api}users.json`)
                .then(res => res.json())
                .then(data => {
                    if (Object.entries(data).some(user => user[1].username === username && user[1].password === password)) {
                        let userInfo = Object.entries(data).find(user => user[1].username === username && user[1].password === password)
                        document.cookie = userInfo[0]
                        navigate("/")
                    } else {
                        alert("your username and password is INCOURECT")
                    }

                })
        } else {
            alert("Your informations is NOT valid")
        }

    }

    return (
        <>
        {show === "login" ? (
        <div className='bg-light'>
            <div className=' px-5 w-75 text-center mx-auto pt-2'>
                <div className='d-flex w-75 text-center mx-auto  px-5'>
                    <div className='p-5'>
                        <img src="/img/login.png" className='border rounded' />
                    </div>
                    <div className=' login-right-section mt-5 '>
                        <div className='bg-white p-5 border'>
                            <div className='p-4 text-center '>
                                <h1 className='main-logo '>instagram</h1>
                                <form className=' w-100 my-3'>
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(4),
                                            maxValid(30)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="username"
                                        type="text" className='form-control w-100 p-3' placeholder='email or phone number' />
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(4),
                                            maxValid(30)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="password"
                                        type="text" className='form-control w-100 my-3 p-3' placeholder='password' />
                                    <button onClick={(e) => getUser(e)}
                                        className='btn btn-primary w-100 p-3 btn-lg my-2'>Log in</button>
                                </form>
                                <div>
                                    <p className='fs-4 my-3'> ---- OR ----</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <span class="material-symbols-outlined">
                                        thumb_up
                                    </span>
                                    <h4 className='mx-2'>Login whith Facebook</h4>
                                </div>
                                <div>
                                    <p className='text-primary fs-5 my-2'>Forgot password</p>
                                </div>
                            </div>

                        </div>
                        <div className='bg-white p-4 my-3 border'>
                            <p onClick={()=> setShow("register")}
                            >Dont have an account ? <Link  className=' text-primary'>Sign up</Link></p>
                        </div>
                        <div>
                            <p>Get the app</p>
                            <div className='d-flex px-5 mt-2'>
                                <span className='d-flex px-1 m-1 py-1 rounded w-50 bg-dark text-white align-items-center'>
                                    <img src="/img/play.png" className='w-25' />
                                    <div>
                                        <p className='fs-6'>GET IT ON</p>
                                        <h6>Google Play</h6>
                                    </div>
                                </span>
                                <span className='d-flex px-1 m-1 py-1 rounded w-50 bg-dark text-white align-items-center'>
                                    <img src="/img/micro.png" className='w-25' />
                                    <div>
                                        <p className='fs-6'>GET IT FROM</p>
                                        <h6>Microsoft</h6>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='login-footer-div'>
                <Footer />
            </div>
        </div>) : (<Register setShow={setShow}/>)}
    </>)
}
