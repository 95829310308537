import React from 'react'
import ReelsBox from '../../Components/Reels/ReelsBox'
import "./Reels.css"

export default function Reels() {
  return (
    <div className='reels-container'>
        <div>
            <ReelsBox file="11.jpg" />
            <ReelsBox file="12.jpg" />
            <ReelsBox file="13.jpg" />
            <ReelsBox file="4.jpg" />
            <ReelsBox file="10.jpg" />
        </div>
    </div>
  )
}
