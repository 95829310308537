import React from 'react'
import SuggestionBox from './SuggestionBox'
import './Suggestions.css'

export default function Suggestions() {
    return (
        <div className='post-container '>
            <div className='p-4'>
                <div className='d-flex-b'>
                    <h4>Suggestions for you</h4>
                    <span className='text-primary'>See all</span>
                </div>
                <div className='d-flex-b'>
                    <SuggestionBox name="kimaro" image="11.jpg"/>
                    <SuggestionBox name="roberto" image="12.jpg" />
                </div>

            </div>
        </div>
    )
}
