import { useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { api } from './api';
import './App.css';
import UserContext from './Context';

import routes from "./routes"

function App() {

  const [userInfos, setUserInfos] = useState(null)
  const [isLogin, setIsLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const router = useRoutes(routes)

  const LogOutUser = () => {
    setIsLogin(false)
    setUserInfos(null)
    document.cookie = `${document.cookie}; expires=Thu, 18 Dec 2000 12:00:00 UTC; path=/;`

  }

  let navigate = useNavigate()

  async function fetchMyAPI() {
    setIsLoading(true)
    let response = await fetch(`${api}users/${document.cookie}.json`)
    let res = await response.json()
    if (res) {
      setUserInfos(res)
      setIsLogin(true)
      setIsLoading(false)
    } else {
      console.log("error in fetch userinfo")
    }
    setIsLoading(false)
  }


  useEffect(() => {

    if (document.cookie) {
      fetchMyAPI()
    } else {
      navigate("/login")
      setIsLogin(false)
    }

  }, [isLogin, navigate])



  return (
    <>
      <UserContext.Provider value={{ userInfos, isLogin, LogOutUser, setIsLogin , isLoading}}>
        {router}
      </UserContext.Provider>
    </>
  );
}

export default App;
