import React from 'react'
import { Link } from 'react-router-dom'
import Follow from '../Follow/Follow'

export default function suggestBox({ name, image, username, id, display , profile }) {
    return (
        <div className='px-3 col-md-12 m-3 d-flex-b'>
            <Link to={`/profile/${username}`}>
                <div className='d-flex-b'>
                    <img src={`/img/${image}`} className='post-header-image' />
                    <div>
                        <h5 className='m-2'>{name}</h5>
                        <h6 className='m-2'>{display === "name" ? username : "Suggested for you"}</h6>
                    </div>
                </div>
            </Link>
            <div >
                {profile ? (<a className='text-primary fs-5' href='#'>switch</a>) : (
                <Follow className='btn text-primary btn-lg' username={username} image={image} fetchId={id} />)}
            </div>
        </div>
    )
}
