import React from 'react'

export default function SuggestionBox({name , image}) {
  return (
    <div className='p-3 text-center border rounded col-md-5 m-3'>
        <div className='px-3 d-flex-b'><div></div><button className='btn flout-right'>X</button></div>
        <img src={`/img/${image}`} className='story-box-image'/>
        <h5 className='m-2'>{name}</h5>
        <h6 className='m-2'>Follow by mick</h6>
        <button className='btn btn-primary text-white btn-lg'>Follow</button>
    </div>
  )
}
