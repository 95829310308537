import React from 'react'
import "./Loader.css"

export default function Loader() {
    return (
        <div className='loader-div-container'>
            <div class="loading">
                <div class="arc"></div>
                <div class="arc"></div>
                <div class="arc"></div>
            </div>
        </div>
     
    )
}
