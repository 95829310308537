import React from 'react'
import "./LeftModal.css"

export default function LeftModal({title , children}) {
  return (
    <div className='left-modal-container'>
        <h1 className='mb-5'>{title}</h1>
        
        {children}
        </div>
  )
}
