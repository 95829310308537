import React, { useEffect, useState } from 'react'
import "./Explore.css"
import ExploreBox from '../../Components/Explore/ExploreBox'
import { api } from '../../api'
import Loader from '../../Components/Loader/Loader'

export default function Explore() {

  const [posts, setPosts] = useState([])

  useEffect(() => {

    async function fetchMyAPI() {
      let response = await fetch(`${api}posts.json`)
      response = await response.json()
      let postInfo = (Object.entries(response))
      setPosts(postInfo)
    }

    fetchMyAPI()
  }, [])

  return (
    <div className='explore-container'>
      <div className='d-flex'>
        {posts.length ? (
          <div className='explore-main'>
            {
              [...posts].reverse().map(post => (<ExploreBox element="image" key={post[0]} {...post[1]} />))
            }
          </div>) : (<Loader />)
        }
      </div>
    </div>
  )
}


