import { Button } from 'bootstrap'
import React from 'react'
import "./ReelsBox.css"

export default function ReelsBox({file}) {
    return (
        <div className='reelsBox-container'>

            <img src={`/img/${file}`} className='reelsBox-image' />

            <span class="material-symbols-outlined  icon-volome">
                volume_up
            </span>

            <span class="material-symbols-outlined  icon-play">
                play_arrow
            </span>

            <div className=' reelsBox-div-icons'>
                <div>
                    <span class="material-symbols-outlined reelsBox-footer-icons">
                        favorite
                    </span>
                    <h6>12K</h6>
                </div>
                <div>
                    <span class="material-symbols-outlined reelsBox-footer-icons">
                        mode_comment
                    </span>
                    <h6>156</h6>
                </div>
                <div>
                    <span class="material-symbols-outlined reelsBox-footer-icons">
                        send
                    </span>
                </div>
                <span class="material-symbols-outlined reelsBox-footer-icons">
                    bookmark
                </span>
                <span class="material-symbols-outlined reelsBox-footer-icons">more_horiz</span>
                <img src='/img/11.jpg' className='reelsBox-footer-image' />
            </div>
            <div className='reelsBox-footer-div'>
                <div className='d-flex align-items-center mb-2'>
                    <img src='/img/13.jpg' className='reelsBox-user-image' />
                    <h4>cristiano .</h4>
                    <span className='btn btn-lg text-white'>Follow</span>
                </div>
                <div>
                    <p>this is the best footballer in the world</p>
                </div>
                <div className='d-flex align-items-center mt-2'>
                    <span class="material-symbols-outlined">
                        music_note
                    </span>
                    <span>gigo zuka sist music</span>
                </div>
            </div>

        </div>
    )
}
