import React from 'react'

export default function Footer() {
    return (
        <div className='my-3 py-3'>
        <ul class="nav">
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5 " aria-current="page" href="#">Meta</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Jobs</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Help</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Terms</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Top Accounts</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Locations</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Instagram Lite</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Contact Uploading & Non-Users</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-secondary fs-5" href="#">Suggested Accounts</a>
            </li>
        </ul>

        <div className='d-flex align-items-center justify-content-center  my-3 p-1'>
            <select className='fs-5 mx-2'>
                <option>English</option>
                <option>Arabic</option>
                <option>turkish</option>
                <option>spanish</option>
                <option>English</option>
                <option>English</option>
                <option>English</option>
            </select>
            <p className='fs-5 mx-2'>© 2023 Instagram from Meta</p>
        </div>

        </div>
    )
}
