const validator = (value , validations) => {

    let arrey = []

    validations.forEach(validation => {
        if(validation.name === "req"){
            value.length < 0 && arrey.push(false)
        } if(validation.name === "min"){
            value.length < validation.min && arrey.push(false)
        } if(validation.name === "max"){
            value.length > validation.max && arrey.push(false)
        }
    });

    console.log(arrey);

    if(arrey.length > 0){
        return false
    } else {
        return true
    }

}

export default validator