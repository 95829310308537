import React from 'react'
import { Link, Outlet } from 'react-router-dom'

export default function Account() {
  return (
    <div className='container py-5'>
            <div className='row border w-75 mx-auto  direct-main-div'>
                <div className='col-md-3 border '>
                    <ul>
                        <Link to="/account/edit"><li className='px-4 py-3'>Edit profile</li></Link>
                        <Link to="changepassword"><li className='px-4 py-3'>Change password</li></Link>
                        <Link><li className='px-4 py-3'>Apps and websites</li></Link>
                        <Link><li className='px-4 py-3'>Email notifications</li></Link>
                        <Link><li className='px-4 py-3'>Push notifications</li></Link>
                        <Link><li className='px-4 py-3'>Manage contacts</li></Link>
                        <Link><li className='px-4 py-3'>Privacy and security</li></Link>
                        <Link><li className='px-4 py-3'>Ads</li></Link>
                        <Link><li className='px-4 py-3'>Supervision</li></Link>
                        <Link><li className='px-4 py-3'>Login activity</li></Link>
                        <Link><li className='px-4 py-3'>Emails from instagram</li></Link>
                        <Link><li className='px-4 py-3'>Help</li></Link>
                        <Link><li className='px-4 py-3 text-primary fs-5 mb-5'>Switch to profetional account</li></Link>
                    </ul>

                    <div className='mt-5 p-3 border-top'>
                        <h3 className='mb-3'>Meta</h3>
                        <h4 className='text-primary mb-3'>Account Center</h4>
                        <p className='fs-5'>Control settings for connected experiences across Instagram,
                             the Facebook app and Messenger, 
                             including story and post sharing and logging in.
                             </p>
                    </div>

                </div>
                <div className='col-md-9 border '>
                   <Outlet/>
                </div>
            </div>
        </div>
  )
}
