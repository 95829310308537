import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import Input from '../../Components/Input/Input'
import { reqValid, minValid, maxValid } from "./../../Validations/rouls"
import useForm from '../../Components/MyHooks/useForm'
import {api} from '../../api'
import UserContext from "./../../Context"

export default function Register({setShow}) {

    const [formState, inputHandler] = useForm({
        username: {
            value: "",
            isValid: false
        },
        password: {
            value: "",
            isValid: false
        },
        name: {
            value: "",
            isValid: false
        },
        email: {
            value: "",
            isValid: false
        },
    }, false)

    console.log(formState);

    let navigate = useNavigate()

    let userContext = useContext(UserContext)

    const registerUser = () => {

        if(formState.inputsValid){
            const id = `${formState.inputs.username.value}${Math.floor(Math.random()* 99999999)}`

            let date = new Date() 
    
            let newUser = {
                id : id ,
                name : formState.inputs.name.value ,
                email : formState.inputs.email.value ,
                username : formState.inputs.username.value ,
                password : formState.inputs.password.value ,
                date,
                posts:[],
                storys:[],
                followers:[],
                followings:[],
                image:"user.png",
                details:[],
                reels:[],
                messages:[]
            }
    
            fetch(`${api}users.json` , {
                method:"POST",
                headers:{
                    "Content-Type" : "application/json"
                },
                body:JSON.stringify(newUser)
            }).then(res =>  res.json())
            .then(data => {
                document.cookie = data.name
                 navigate("/")
                 userContext.setIsLogin(true)
            })
        } else {
            alert("Your informations is NOT valid")
        }
        
       
    }

    return (
        <div className='bg-light'>
            <div className=' px-5 w-75 text-center mx-auto pt-2'>
                <div className='d-flex w-75 text-center mx-auto mt-5 px-5'>
                    <div className='p-5'>
                        <img src="/img/login.png" className='border rounded' />
                    </div>
                    <div className=' login-right-section  mt-5'>
                        <div className='bg-white p-5 border'>
                            <div className='p-4 text-center '>
                                <h1 className='main-logo '>instagram</h1>
                                <h4 className='mb-4'>Sign up to see photos and videos from your friends.</h4>
                                <button className='btn btn-primary w-100 p-2 btn-lg my-2 d-flex align-items-center justify-content-center'>

                                    <span class="material-symbols-outlined">
                                        thumb_up
                                    </span>
                                    <h4 className='mx-2'>Login whith Facebook</h4>
                                </button>
                                <div>
                                    <p className='fs-4 my-3'> ---- OR ----</p>
                                </div>
                                <form className=' w-100 my-3'>
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(6),
                                            maxValid(30)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="email"
                                        type="text" className='form-control w-100 p-3' placeholder='email or phone number' />
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(4),
                                            maxValid(20)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="name"
                                        type="text" className='form-control w-100 my-3 p-3' placeholder='Full Name' />
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(4),
                                            maxValid(30)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="username"
                                        type="text" className='form-control w-100 my-3 p-3' placeholder='Username' />
                                    <Input
                                        valdations={[
                                            reqValid(),
                                            minValid(4),
                                            maxValid(20)
                                        ]}
                                        inputHandler={inputHandler}
                                        id="password"
                                        type="password" className='form-control w-100 my-3 p-3' placeholder='password' />
                                    
                                </form>
                                <div>
                                    <p className=' fs-5 my-3'>People who use our service may have uploaded your contact information to Instagram. Learn more</p>
                                </div>
                                <div>
                                    <p className=' fs-5 my-2'>By signing up, you agree to our Terms , Privacy Policy and Cookies Policy .</p>
                                </div>
                                <button onClick={registerUser}
                                className='btn btn-primary w-100 p-3 btn-lg my-2'>Sign up</button>
                            </div>

                        </div>
                        <div className='bg-white p-4 my-3 border'>
                            <p onClick={()=> setShow("login")}
                            >Have an account ? <Link  className=' text-primary'>Log in</Link></p>
                        </div>
                        <div>
                            <p>Get the app</p>
                            <div className='d-flex px-5 mt-2'>
                                <span className='d-flex px-1 m-1 py-1 rounded w-50 bg-dark text-white align-items-center'>
                                    <img src="/img/play.png" className='w-25' />
                                    <div>
                                        <p className='fs-6'>GET IT ON</p>
                                        <h6>Google Play</h6>
                                    </div>
                                </span>
                                <span className='d-flex px-1 m-1 py-1 rounded w-50 bg-dark text-white align-items-center'>
                                    <img src="/img/micro.png" className='w-25' />
                                    <div>
                                        <p className='fs-6'>GET IT FROM</p>
                                        <h6>Microsoft</h6>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='login-footer-div'>
                <Footer />
            </div>
        </div>
    )
}
