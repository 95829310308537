import React from 'react'
import { Link } from 'react-router-dom'
import "./Explore.css"

export default function ExploreBox({ element , file , id}) {
    return (
        <>
            {element === "image" ? ( 
            <Link to={`/post/${id}`}>
            <div className='explore-box-container p-3'>
                <img src={`/img/${file}`} className='explore-box-image' />
                <span class="material-symbols-outlined">
                    folder_copy
                </span>
            </div>
            </Link>
            ) : (
                <Link to="/post/id">
                <div className='explore-box-container-reels p-3'>
                    <img src={`/img/${file}`} className='explore-box-image' />
                    <span class="material-symbols-outlined">
                        smart_display
                    </span>
                </div>
                </Link>
            )}

        </>


    )
}
