import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../api'
import Loader from '../../Components/Loader/Loader'
import "./PostModal.css"

export default function PostModal() {
    const [post, setPost] = useState({})
    const navigate = useNavigate()

    const { postId } = useParams()

    useEffect(() => {

        async function fetchMyAPI() {
            let response = await fetch(`${api}posts.json`)
            response = await response.json()
            console.log(response);
            for (const key in response) {
                if (response[key].id == postId) {
                    const element = response[key];
                    setPost(element)
                }
            }
        }

        fetchMyAPI()
    }, [postId])

    return (
        <div className='post-modal-container'>
            {post.file ? (<>
                <div className='w-100 px-5'>
                    <span onClick={() => navigate(-1)}
                        className=' text-white fs-1  btn float-end'
                    >X</span>
                </div>
                <div className='d-flex align-items-center justify-content-center post-modal-main'>

                    <div className='w-50 h-100 bg-white'>
                        <img src={`/img/${post.file}`} className='w-100 h-100' />
                    </div>
                    <div className='w-50 h-100 bg-white'>
                        <div className='post-main-container '>

                            <div className='post-header mb-3 border-bottom'>
                                <Link to={`/profile/${post.username}`}>
                                    <div className='post-header-account'>
                                        <img src={`/img/${post.image}`} className='post-header-image' />
                                        <h4 className=''>{post.username}</h4>
                                    </div>
                                </Link>
                                <span class="material-symbols-outlined">more_horiz</span>
                            </div>
                            <div className='px-3 py-1 d-flex'>
                                <img src={`/img/${post.image}`} className='post-header-image' />
                                <div>
                                    <p>
                                        <h4 className='d-inline'>{post.username}</h4>&nbsp;&nbsp;&nbsp;
                                        {post.caption}{' '}

                                    </p>
                                    <h6 className='post-more'>4 DAYS AGO</h6>
                                </div>
                            </div>
                            <div className='post-modal-comments '>
                                {post.comments ? (<>
                                    {post.comments.map(comment => (
                                        <div className='p-3  d-flex align-items-center' key={post.id}>
                                            <img src={`/img/10.jpg`} className='post-header-image' />
                                            <div>
                                                <p>
                                                    <h4 className='d-inline'>uservigo</h4>&nbsp;&nbsp;&nbsp;
                                                    {comment}{' '}

                                                </p>
                                                <h6 className='post-more'>13h</h6>
                                            </div>
                                            <span class="material-symbols-outlined ms-auto fs-5">
                                                favorite
                                            </span>
                                        </div>
                                    ))}
                                </>) : (
                                    <div className='w-100 h-100 pt-5 mt-5 d-flex align-items-center justify-content-center'>
                                        <div>
                                        <h2 className='mt-5 pt-5'>No comments yet</h2>
                                        <p className='text-center mb-5 pb-5 pt-3'>Start the conversation.</p>
                                        </div>
                                    </div>)}
                            </div>
                            <div className='post-footer px-3 mt-5'>
                                <div className=' d-flex-b py-3'>
                                    <div className='post-footer-icons-div'>
                                        <span class="material-symbols-outlined">
                                            favorite
                                        </span>

                                        <span class="material-symbols-outlined">
                                            mode_comment
                                        </span>
                                        <span class="material-symbols-outlined">
                                            send
                                        </span>
                                    </div>
                                    <div className='post-footer-icons-div'>
                                        <span class="material-symbols-outlined">
                                            bookmark
                                        </span>
                                    </div>
                                </div>
                                <div >
                                    <h4>{post.likes || 0} likes</h4>
                                </div>



                            </div>
                            <div className=' post-comment px-3'>
                                <span class="material-symbols-outlined">
                                    sentiment_satisfied
                                </span>
                                <input type="text" placeholder='add a comment...' />
                                <span className='text-primary'>Post</span>
                            </div>

                        </div>
                    </div>

                </div></>) : (<Loader />)}
        </div>
    )
}
